<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-4" dark color="blue" v-bind="attrs" v-on="on">
        <v-icon dark>
          mdi-plus
        </v-icon>
        Nuevo
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Nuevo profesor
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Nombre"
          v-model="nuevoProfesor.nombre"
        ></v-text-field>

        <v-text-field
          label="Primer apellido"
          v-model="nuevoProfesor.primerApellido"
        ></v-text-field>

        <v-text-field
          label="Segundo Apellido"
          v-model="nuevoProfesor.segundoApellido"
        ></v-text-field>

        <v-text-field label="DNI" v-model="nuevoProfesor.dni"></v-text-field>

        <v-menu
          ref="menu"
          v-model="fechaNacimiento"
          :close-on-content-click="false"
          :return-value.sync="nuevoProfesor.fechaNacimiento"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="nuevoProfesor.fechaNacimiento"
              label="Fecha de nacimiento"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="nuevoProfesor.fechaNacimiento"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="fechaNacimiento = false">
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="nuevoProfesor.fechaNacimiento = ''"
            >
              Limpiar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(nuevoProfesor.fechaNacimiento)"
            >
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field
          label="Domicilio"
          v-model="nuevoProfesor.domicilio"
        ></v-text-field>

        <v-text-field
          label="Teléfono"
          v-model="nuevoProfesor.telefono"
        ></v-text-field>

        <v-text-field
          label="Correo"
          v-model="nuevoProfesor.correo"
        ></v-text-field>

        <v-text-field
          label="Observaciones"
          v-model="nuevoProfesor.observaciones"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="crearProfesor()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NuevoProfesor',
  data() {
    return {
      loading: false,
      toggleDialog: false,
      fechaNacimiento: false,
      nuevoProfesor: {
        nombre: '',
        primerApellido: '',
        segundoApellido: '',
        dni: '',
        fechaNacimiento: '',
        domicilio: '',
        telefono: '',
        correo: '',
        observaciones: '',
      },
    };
  },
  methods: {
    async crearProfesor() {
      try {
        this.loading = true;

        const res = await axios.post(
          process.env.VUE_APP_API_URL + '/profesores/nuevo',
          this.nuevoProfesor
        );

        this.loading = false;

        this.toggleDialog = false;

        this.$router.push({
          name: 'Profesor',
          params: { id: res.data._id },
        });
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
